import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { P2pBuyingService } from '@core/p2p-services/p2p-buying.service';
import { P2PModalService } from '@core/p2p-services/p2p-modal.service';
import type { IBasedForm, IMarketplaceKitData, IUICheckboxLabel } from '@dev-fast/types';
import { AgreementName, ModalNames, NotificationStatus, NotificationType, PROJECT, QuickFaqName } from '@dev-fast/types';
import { TranslatePipe } from '@ngx-translate/core';
import type { Observable } from 'rxjs';
import { combineLatest, map, merge, pairwise, Subject, take, tap } from 'rxjs';

import { EnvironmentService } from '@app/core/environment-service';
import { NotificationsService } from '@app/core/notification-service';
import { AgreementsService } from '@app/core/state/agreements';
import { QuickFaqService } from '@app/core/state/faq';

import { expansionAnimation } from '../shared/animations/expansion.animation';

@Component({
  selector: 'app-p2p-purchase',
  templateUrl: './p2p-purchase.component.html',
  styleUrls: ['./p2p-purchase.component.scss', '../styles/modal.scss'],
  animations: [expansionAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TranslatePipe],
})
export class P2pPurchaseComponent implements OnInit {
  readonly #destroyRef = inject(DestroyRef);
  readonly #agreementsService = inject(AgreementsService);
  readonly #p2pBuyingService = inject(P2pBuyingService);
  readonly #p2pModalService = inject(P2PModalService);
  readonly #quickFaqService = inject(QuickFaqService);
  readonly #environmentService = inject(EnvironmentService);
  readonly #notifyService = inject(NotificationsService);

  readonly #translate = inject(TranslatePipe);

  readonly #isProjectMarket = this.#environmentService.isProject({ name: PROJECT.MARKET });

  readonly checkValidation$: Subject<void> = new Subject();
  readonly purchasing$: Observable<IMarketplaceKitData[]> = this.purchasingWatch$;

  readonly showWarn$: Observable<boolean> = this.#p2pBuyingService.showWarn$;
  readonly rwtAgree$: Observable<boolean> = this.#agreementsService.agreementByName$(AgreementName.RWT);
  readonly rwtForm: FormGroup<IBasedForm> = new FormGroup({
    agreement: new FormControl(false, {
      validators: Validators.requiredTrue,
      nonNullable: true,
    }),
  });

  readonly agreementLabels: IUICheckboxLabel[] = [
    {
      title: 'MARKET.RWT.AGREEMENT_P1',
    },
    {
      title: 'MARKET.RWT.AGREEMENT_LINK',
      href: this.#isProjectMarket ? '/faq/rwt' : '/tos',
      target: '_blank',
    },
    {
      title: 'MARKET.RWT.AGREEMENT_P2',
    },
  ];

  isLoadingBids$: Observable<boolean> = this.#p2pBuyingService.isLoadingBids$;

  ngOnInit(): void {
    merge(this.agreementByNameWatch$, this.agreementValueChangesWatch$, this.combineWatch$)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe();
  }

  onChangeNotifiedStatus(value: boolean): void {
    this.#p2pBuyingService.updateShowWarnValue(value);
  }

  trackingFunction(index: number, item: IMarketplaceKitData): number {
    return item.id;
  }

  goToStoreTab(): void {
    this.#p2pBuyingService.goToStoreMarketTab();
  }

  goToTabAndClosePanel(): void {
    this.#p2pBuyingService.closeMarketPanel();
  }

  agreeRWT(): void {
    this.#agreementsService.setAgreement(AgreementName.RWT, true);
  }

  checkValidation(): void {
    this.rwtForm.controls.agreement.markAsDirty();
    this.checkValidation$.next();
  }

  /**
   * Отслеживание изменения списка выбранных предметов.
   * Уведомление об изменении цены.
   */
  get purchasingWatch$(): Observable<IMarketplaceKitData[]> {
    return this.#p2pBuyingService.purchasing$.pipe(
      pairwise(),
      tap(([prevItems, currItems]) => {
        const isItemsChange = currItems.length !== 0 && prevItems.length === currItems.length;
        if (!isItemsChange) {
          return;
        }

        const changedItems = currItems.filter((item, index) => prevItems[index].price !== item.price);
        if (changedItems.length === 0) {
          return;
        }

        const changedItemsNames = changedItems.map((item) => item.items[0].baseItem.name).join(', ');

        const notifyMessage: string = this.#translate.transform('P2P_WIDGETS.PURCHASE.PRICE_UPDATE') + ': ' + changedItemsNames;

        this.#notifyService.addNotification({
          id: Date.now(),
          type: NotificationType.Info,
          icon: 'info',
          message: notifyMessage,
          createDate: Date.now(),
          status: NotificationStatus.new,
          system: true,
          updateNotification: true,
        });
      }),
      map(([prevItems, currItems]) => currItems),
    );
  }

  get agreementByNameWatch$(): Observable<boolean> {
    return this.#agreementsService.agreementByName$(AgreementName.RWT).pipe(
      tap((isAgree) => {
        this.rwtForm.controls.agreement.patchValue(isAgree, { emitEvent: false });
      }),
    );
  }

  get agreementValueChangesWatch$(): Observable<boolean> {
    return this.rwtForm.controls.agreement.valueChanges.pipe(
      tap((isAgree) => {
        this.#agreementsService.setAgreement(AgreementName.RWT, isAgree);
      }),
    );
  }

  get combineWatch$(): Observable<[boolean, boolean, boolean]> {
    return combineLatest([
      this.#p2pModalService.isSomeModalOpened$,
      this.#quickFaqService.quickFaqStatus$(QuickFaqName.RWT),
      this.#agreementsService.agreementByName$(AgreementName.RWT),
    ]).pipe(
      tap(([someModalOpened, wasClosed, isRWTAgree]) => {
        if (wasClosed || isRWTAgree) {
          return;
        }
        if (!someModalOpened && this.#isProjectMarket) {
          this.#p2pModalService.openModal(ModalNames.P2P_RWT, true);
        }
      }),
      take(1),
    );
  }
}
